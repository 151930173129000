@import "custom";
@import "fonts";

$font-color-black: #111014;
$font-color-gray: #7b7686;

body {
  padding: 0 2.5rem 2.5rem;

  @include media-breakpoint-down(lg) {
    padding: 0 1.5rem 1.5rem;
  }
  @include media-breakpoint-down(md) {
    padding: 0 0.5rem 0.5rem 0.5rem;
  }
}

.btn-primary.bg-gradient {
  &:hover {
    background-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      linear-gradient(to right, #4a52ff, #7b29ff) !important;
  }
}

.navbar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 1.5rem;

  @include media-breakpoint-down(md) {
    padding: 1rem;
  }

  .navbar-collapse {
    justify-content: center;
    .navbar-nav {
      align-items: center;
      .nav-link {
        font-family: SpaceGrotesk;
        font-weight: 500;
        text-align: center;
        @include media-breakpoint-down(xl) {
          padding: 1.75rem 0;
          color: $font-color-black;
          &.active {
            color: $font-color-black;
          }
        }
        @include media-breakpoint-down(md) {
          padding: 1rem 0;
        }

        &.active {
          color: $font-color-black;
        }
        &.contact-us-btn {
          padding: 1rem 1.5rem;
          color: #fff;
          font-size: 1.125rem;
          line-height: 1.5rem;
          font-weight: 400;
          @include media-breakpoint-down(xl) {
            margin: 1.75rem 0 2rem;
          }
          @include media-breakpoint-down(md) {
            margin: 1rem 0 1.25rem;
          }
        }
      }
    }
    .contact-us-btn {
      padding: 1rem 1.5rem;
    }
  }

  .navbar-toggler {
    background: var(--bs-gradient);
    box-shadow: none;
    width: 3.5rem;
    height: 3.5rem;
    &.collapsed {
      .navbar-toggler-icon {
        background-image: url("../assets/open-navbar.svg");
      }
    }
    .navbar-toggler-icon {
      background-image: url("../assets/close-navbar.svg");
      @include media-breakpoint-down(md) {
        font-size: 1rem;
        padding: 0.75rem;
      }
    }
  }
}

.intro-container {
  background-image: linear-gradient(180deg, rgb(20, 29, 36), rgb(17, 16, 20));
  border-radius: 3.5rem;

  padding: 7.25rem 4rem;

  color: $font-color-gray;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(xxl) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @include media-breakpoint-down(xl) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 2rem 1.5rem;
    border-radius: 2.5rem;
  }

  .container-bg {
    width: 137rem;
    top: 117%;
    @include media-breakpoint-down(xl) {
      top: 96%;
    }
    @include media-breakpoint-down(lg) {
      width: 86rem;
      top: 89%;
    }
  }

  .what-we-build-btn {
    align-self: flex-start;
  }

  .intro-container-bg-img {
    position: absolute;
    top: 50%;
    left: -50%;
  }

  .intro-title-wrapper {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .intro-title {
    max-width: 57.5rem;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    font-size: 5.5rem;
    line-height: 100%;
    background-image: linear-gradient(to bottom, #ffffff 0%, #4f4fff 100%);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

    @include media-breakpoint-down(xxl) {
      font-size: 4.5rem;
    }

    @include media-breakpoint-down(xl) {
      font-size: 4rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
    }
  }

  .intro-description-wrapper {
    margin-bottom: 2.5rem;
    text-align: center;
  }

  .intro-description {
    display: inline-block;
    max-width: 47.5rem;
    font-size: 1.25rem;
    line-height: 150%;
    color: $font-color-gray;
    @include media-breakpoint-down(xl) {
      max-width: 100%
    }
  }

  .partners-block {
    gap: 0.5rem;
    @include media-breakpoint-down(xl) {
      margin-top: 3.75rem;
    }

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }

    .partner-item {
      width: 100%;
      max-width: 7.875rem;
      border-radius: 1rem;
      background-color: #25232b;
      padding: 0.93rem;
      flex: 1;
    }

    .text {
      font-size: 0.875rem;
      line-height: 150%;
      color: $font-color-gray;
    }
  }

  .team-block {
    @include media-breakpoint-down(xl) {
      margin-top: 3.75rem;
    }

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }

    .team-container {
      padding-left: 1.5rem;
      &:nth-child(1) {
        z-index: 6;
      }
      &:nth-child(2) {
        z-index: 5;
      }
      &:nth-child(3) {
        z-index: 4;
      }
      &:nth-child(4) {
        z-index: 3;
      }
      &:nth-child(5) {
        z-index: 2;
      }
      &:nth-child(6) {
        z-index: 1;
      }
    }

    .text {
      font-size: 0.875rem;
      line-height: 150%;
      color: $font-color-gray;
    }

    .profile-image {
      width: 3.75rem;
      height: 3.75rem;
      border: 3px solid #111014;
      margin-left: -0.75rem;
      cursor: pointer;
      filter: grayscale(1);

      @include media-breakpoint-down(md) {
        width: 3.5625rem;
        height: 3.5625rem;
      }

      &.text {
        background-color: #25232a;
        display: flex;
        > span {
          font-weight: 700;
          font-size: 0.875rem;
          line-height: 150%;
          color: #fff;
          margin: auto;
        }
      }
    }
  }
}

.expertise-container {
  padding: 6.25rem 0;

  @include media-breakpoint-down(lg) {
    padding: 5rem 0;
  }
  @include media-breakpoint-down(md) {
    padding: 4rem 0.5rem;
  }

  .container-title {
    color: $font-color-black;
    font-weight: 700;
    font-size: 4rem;
    line-height: 120%;
    margin-bottom: 5rem;
    letter-spacing: -0.02em;

    @include media-breakpoint-down(lg) {
      text-align: center;
      font-size: 3rem;
      margin-bottom: 4rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  .nav-link {
    text-align: left;
    font-size: 2rem;
    line-height: 120%;
    font-weight: 700;
    padding: 1.5rem;
    white-space: pre-line;
    flex: 1;
    &:hover {
      color: $font-color-gray;
    }
    &.bg-gradient {
      color: #fff;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.25rem;
      line-height: 120%;
    }
    @include media-breakpoint-down(md) {
      padding: 0.75rem;
      font-size: 0.8125rem;
      line-height: 120%;
      border-radius: 1rem;
    }
  }

  .tab-content {
    @include media-breakpoint-down(lg) {
      padding: 0 4rem;
    }
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .tab-content-stack {
    gap: 3.5rem;
    @include media-breakpoint-down(lg) {
      margin-top: 4rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
      gap: 2rem;
    }
  }

  .tab-content-item {
    .title {
      color: $font-color-black;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 140%;
      margin: 1rem 0;
      display: inline-block;
      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
      }
    }

    .description {
      color: $font-color-gray;
      font-size: 1.5rem;
      line-height: 150%;
      display: inline-block;
      @include media-breakpoint-down(md) {
        font-size: var(--bs-font-size-sm);
      }
    }
  }
}

.tech-stack-container {
  background-image: linear-gradient(180deg, rgb(20, 29, 36), rgb(17, 16, 20));
  border-radius: 3.5rem;
  padding: 5rem 0;

  color: #fff;

  @include media-breakpoint-down(lg) {
    padding: 4rem 0;
  }

  @include media-breakpoint-down(md) {
    padding: 2rem 0;
    border-radius: 2.5rem;
  }

  .container-title {
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    line-height: 120%;
    letter-spacing: -0.02em;

    margin-top: 2.5rem;
    margin-bottom: 4rem;

    @include media-breakpoint-down(lg) {
      margin-bottom: 3rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 3rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .tech-stack-item {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    @include media-breakpoint-down(lg) {
      padding-top: 2.7rem;
      padding-bottom: 2.7rem;
    }
    @include media-breakpoint-down(lg) {
      padding-top: 1.39rem;
      padding-bottom: 1.39rem;
    }

    img {
      width: 4rem;
      margin-bottom: 1.5rem;
      @include media-breakpoint-down(lg) {
        width: 2.5rem;
      }
      @include media-breakpoint-down(md) {
        width: 2rem;
      }
    }
    span {
      color: $font-color-gray;
      font-weight: 500;
      font-size: 1.125rem;
      @include media-breakpoint-down(lg) {
        font-size: 1rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
      }
    }
  }

  .another-technology {
    margin-top: 4rem;

    @include media-breakpoint-down(lg) {
      margin-top: 3rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }

    span {
      font-size: 2rem;
      line-height: 150%;
      @include media-breakpoint-down(lg) {
        font-size: 1.5rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }

    :first-child {
      color: #fff;
    }

    :nth-child(2) {
      color: $font-color-gray;
    }

    .contact-us-btn {
      margin-top: 2.5rem;
    }
  }
}

.how-we-work-container {

  padding-top: 6.25rem;

  @include media-breakpoint-down(lg) {
    padding-top: 5rem;
  }
  @include media-breakpoint-down(md) {
    padding-top: 4rem;
  }

  .container-title {
    color: $font-color-black;
    font-weight: 700;
    font-size: 4rem;
    line-height: 120%;
    margin-bottom: 5rem;
    letter-spacing: -0.02em;

    @include media-breakpoint-down(lg) {
      text-align: center;
      font-size: 3rem;
      margin-bottom: 4rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  .work-process-card {
    padding: 3rem;
    border-radius: 3.5rem;
    background-color: #f6f5f8;

    @include media-breakpoint-down(xxl) {
      padding: 2.5rem;
    }
    @include media-breakpoint-down(xl) {
      padding: 3rem 5rem;
      align-items: center;
      text-align: center;
    }
    @include media-breakpoint-down(lg) {
      padding: 3rem;
    }
    @include media-breakpoint-down(md) {
      padding: 2rem 1rem;
    }

    .work-process-card-header {
      display: inline-block;
      background-color: #fff;
      font-size: 1.5rem;
      border-radius: 1rem;
      color: $font-color-gray;
      padding: 1rem 1.5rem;
      align-self: flex-start;
      @include media-breakpoint-down(xl) {
        align-self: center;
      }

      span {
        color: $font-color-black;
      }
    }

    img {
      width: 14.0625rem;
      margin: 3.5rem 0;
      @include media-breakpoint-down(xl) {
        margin: 3rem;
      }
      @include media-breakpoint-down(md) {
        margin: 2rem;
        width: 13.125rem;
      }
    }

    .work-process-card-title {
      color: $font-color-black;
      font-size: 2.5rem;
      line-height: 110%;
      font-weight: 700;
      margin-bottom: 2.5rem;
      @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }
    }

    .work-process-card-description {
      color: $font-color-gray;
      font-size: 1.125rem;
      line-height: 150%;
      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
      }
    }
  }
}

.portfolio-container {
  padding-top: 6.25rem;

  @include media-breakpoint-down(lg) {
    padding-top: 5rem;
  }
  @include media-breakpoint-down(md) {
    padding-top: 4rem;
  }

  .container-title {
    color: $font-color-black;
    font-weight: 700;
    font-size: 4rem;
    line-height: 120%;
    margin-bottom: 5rem;
    letter-spacing: -0.02em;

    @include media-breakpoint-down(lg) {
      text-align: center;
      font-size: 3rem;
      margin-bottom: 4rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  .portfolio-list {
    gap: 1rem;
  }

  .portfolio-item {
    background-image: linear-gradient(180deg, #1e1d24, #111014);
    border-radius: 3.5rem;
    padding: 6.25rem;

    @include media-breakpoint-down(xxl) {
      padding: 5rem;
    }
    @include media-breakpoint-down(lg) {
      padding: 3rem;
    }
    @include media-breakpoint-down(md) {
      padding: 2rem;
    }

    .portfolio-item-info {
      gap: 2.5rem;
      @include media-breakpoint-down(md) {
        gap: 2rem;
      }
    }

    .portfolio-item-title {
      color: #fff;
      font-size: 3rem;
      font-weight: 700;
      line-height: 110%;
      white-space: pre-line;
      @include media-breakpoint-down(xl) {
        margin-top: 5rem;
      }
      @include media-breakpoint-down(lg) {
        margin-top: 4rem;
        font-size: 2.5rem;
      }
      @include media-breakpoint-down(md) {
        margin-top: 2.5rem;
        font-size: 2rem;
      }
    }

    .portfolio-item-subtitle {
      color: #fff;
      font-size: 1.5rem;
      line-height: 130%;
      font-weight: 300;
      white-space: pre-line;
      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
      }
    }

    .portfolio-item-description {
      color: $font-color-gray;
      line-height: 150%;
      white-space: pre-line;
      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
        margin-top: -0.5rem;
      }
    }

    @mixin industry-colors($bg-color, $border-color) {
      background: $bg-color;
      border: 1px solid $border-color;
    }

    .portfolio-industries {
      span {
        display: inline-block;
        border-radius: 0.5rem;
        padding: 0.5rem 0.75rem;
        line-height: 1rem;
        color: #fff;
        margin-right: 0.5rem;
        @include media-breakpoint-down(md) {
          margin-bottom: 0.5rem;
        }
      }
    }

    $fintech-bg-color: rgba(103, 161, 144, 0.16);
    $fintech-boder-color: rgba(103, 162, 144, 0.72);
    $pontem-bg-color: rgba(209, 79, 255, 0.16);
    $pontem-border-color: rgba(209, 79, 255, 0.72);
    $pontem-bg-hover-color: #d14fff;
    $liquidswap-bg-color: rgba(3, 179, 201, 0.16);
    $liquidswap-border-color: rgba(25, 123, 130, 0.72);
    $liquidswap-bg-hover-color: #197B82;
    $nftstays-bg-color: rgba(8, 150, 255, 0.16);
    $nftstays-border-color: rgba(8, 150, 255, 0.72);
    $mpc-bg-color: rgba(79, 79, 255, 0.16);
    $mpc-border-color: rgba(79, 79, 255, 0.72);
    $totem-bg-color: rgba(79, 79, 255, 0.16);
    $totem-border-color: rgba(79, 79, 255, 0.72);
    $totem-bg-hover-color: #4f4fff;
    $tokenization-platform-bg-color: rgba(8, 150, 255, 0.16);
    $tokenization-platform-border-color: rgba(8, 150, 255, 0.72);
    $tokenization-platform-bg-hover-color: #0896ff;

    .portfolio-industries.fintech-item span {
      @include industry-colors($fintech-bg-color, $fintech-boder-color);
    }
    .portfolio-industries.pontem-item span {
      @include industry-colors($pontem-bg-color, $pontem-border-color);
    }
    .portfolio-industries.liquidswap-item span {
      @include industry-colors($liquidswap-bg-color, $liquidswap-border-color);
    }
    .portfolio-industries.nftstays-item span {
      @include industry-colors($nftstays-bg-color, $nftstays-border-color);
    }
    .portfolio-industries.mpc-item span {
      @include industry-colors($mpc-bg-color, $mpc-border-color);
    }
    .portfolio-industries.totem-item span {
      @include industry-colors($totem-bg-color, $totem-border-color);
    }
    .portfolio-industries.tokenization-platform-item span {
      @include industry-colors(
        $tokenization-platform-bg-color,
        $tokenization-platform-border-color
      );
    }

    .portfolio-technologies {
      @include media-breakpoint-down(xl) {
        max-width: 70%;
      }
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }
      span {
        display: inline-block;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 0.5rem;
        padding: 0.5rem 0.75rem;
        line-height: 1rem;
        color: #fff;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .visit-website-btn {
      padding: 1rem 1.5rem;
      align-self: flex-start;
      span {
        margin-right: 0.5rem;
      }
    }

    .visit-website-btn.fintech-item {
      @include industry-colors($fintech-bg-color, $fintech-boder-color);
    }
    .visit-website-btn.pontem-item {
      @include industry-colors($pontem-bg-color, $pontem-border-color);
      &:hover {
        @include industry-colors(
          $pontem-bg-hover-color,
          $pontem-bg-hover-color
        );
      }
    }
    .visit-website-btn.liquidswap-item {
      @include industry-colors($liquidswap-bg-color, $liquidswap-border-color);
      &:hover {
        @include industry-colors(
          $liquidswap-bg-hover-color,
          $liquidswap-bg-hover-color
        );
      }
    }
    .visit-website-btn.nftstays-item {
      @include industry-colors($nftstays-bg-color, $nftstays-border-color);
    }
    .visit-website-btn.mpc-item {
      @include industry-colors($mpc-bg-color, $mpc-border-color);
    }
    .visit-website-btn.totem-item {
      @include industry-colors($totem-bg-color, $totem-border-color);
      &:hover {
        @include industry-colors($totem-bg-hover-color, $totem-bg-hover-color);
      }
    }
    .visit-website-btn.tokenization-platform-item {
      @include industry-colors(
        $tokenization-platform-bg-color,
        $tokenization-platform-border-color
      );
      &:hover {
        @include industry-colors(
          $tokenization-platform-bg-hover-color,
          $tokenization-platform-bg-hover-color
        );
      }
    }
  }
}

.testimonials-container {
  padding: 6.25rem 0;

  @include media-breakpoint-down(lg) {
    padding: 5rem 0;
  }
  @include media-breakpoint-down(md) {
    padding: 4rem 0;
  }

  .container-title {
    color: $font-color-black;
    font-weight: 700;
    font-size: 4rem;
    line-height: 120%;
    letter-spacing: -0.02em;

    @include media-breakpoint-down(xl) {
      margin-bottom: 5rem;
      display: inline-block;
      text-align: center;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 4rem;
      font-size: 3rem;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
      font-size: 2rem;
    }

    span {
      color: #4b52ff;
    }
  }

  .testimonials-controls {
    margin-top: 3.5rem;
    gap: 1.5rem;

    @include media-breakpoint-down(xl) {
      margin-top: 5rem;
    }
    @include media-breakpoint-down(lg) {
      margin-top: 4rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }

    :last-child {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .testimonials-control {
    border: 1px solid $font-color-black;
    width: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3.5rem;
  }

  .testimonials-item {
    padding: 3.5rem;
    border-radius: 3.5rem;
    background-color: rgba(246, 245, 248, 1);
    @include media-breakpoint-down(xl) {
      padding: 5rem 6.25rem;
    }
    @include media-breakpoint-down(lg) {
      padding: 4rem 3rem;
    }
    @include media-breakpoint-down(md) {
      padding: 2rem;
      border-radius: 2.5rem;
    }

    .testimonial-text {
      white-space: pre-line;
      font-size: 1.125rem;
      line-height: 150%;
      color: $font-color-black;
      @include media-breakpoint-down(xl) {
        font-size: 1.4rem;
      }
      @include media-breakpoint-down(lg) {
        font-size: 1rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
      }
    }

    .reviewer-block {
      margin-bottom: 2.5rem;

      .reviewer-photo {
        width: 5rem;
        border-radius: 1.5rem;
        margin-right: 1.5rem;
        @include media-breakpoint-down(md) {
          width: 4rem;
          margin-right: 0;
          margin-bottom: 1.5rem;
        }
      }
      .reviewer-name {
        line-height: 100%;
        font-weight: 700;
        color: $font-color-black;
        font-size: 2rem;
        display: block;
        margin-bottom: 0.75rem;
        @include media-breakpoint-down(lg) {
          font-size: 1.5rem;
        }
      }
      .reviewer-job {
        line-height: 100%;
        color: $font-color-gray;
        font-size: 1.5rem;
        display: block;
        @include media-breakpoint-down(lg) {
          font-size: 1.25rem;
        }
      }
    }
  }
}

.contact-us-container {
  background-image: linear-gradient(180deg, rgb(20, 29, 36), rgb(17, 16, 20));
  border-radius: 3.5rem;
  padding: 6.25rem 0;
  position: relative;

  color: #fff;

  @include media-breakpoint-down(xl) {
    padding: 5rem;
  }
  @include media-breakpoint-down(lg) {
    padding: 3rem;
  }

  @include media-breakpoint-down(md) {
    padding: 2rem 1rem 3rem 1rem;
  }

  .logo {
    width: 2.875rem;
    @include media-breakpoint-down(xl) {
      align-self: center;
    }
  }

  .container-title {
    color: #fff;
    font-weight: 700;
    font-size: 4rem;
    line-height: 120%;
    letter-spacing: -0.02em;

    margin: 2.5rem 0;

    @include media-breakpoint-down(lg) {
      font-size: 3rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
      margin: 2rem 0;
    }
  }

  .container-description {
    font-size: 1.25rem;
    line-height: 150%;
    white-space: pre-line;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }

  .social-media {
    gap: 1.5rem;
    margin-top: 2.5rem;
    @include media-breakpoint-down(xl) {
      justify-content: center;
      margin-top: 4rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }

    span {
      font-size: 1.25rem;
      color: $font-color-gray;
      line-height: 1.5rem;
    }

    .linkedin-btn {
      border: 1px solid rgba(255, 255, 255, 0.24);
      width: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3.5rem;
    }
  }

  .contact-us-form {
    margin: 0 3.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include media-breakpoint-down(xl) {
      margin: 4rem 0 0;
    }
    @include media-breakpoint-down(md) {
      margin: 2rem 0 0;
    }

    .input {
      background-color: #25232a;
      border-radius: 1.5rem;
      padding: 1.5rem;
      color: #fff;
      border-color: transparent;
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-weight: 500;
      border-width: 2px;
      border-color: #25232a;
      &::placeholder {
        color: rgba(123, 118, 134, 1);
      }

      &.invalid {
        background-image: none;
        background: linear-gradient(#25232a, #25232a) padding-box,
          linear-gradient(to right, #ff4a60, #ff4a60) border-box;
        border-color: transparent;
        border-width: 1px;
        padding: calc(1.5rem + 1px);
      }

      &.valid {
        background-image: none;
        background-color: #25232a;
      }

      @include media-breakpoint-up(xl) {
        &:hover {
          background: linear-gradient(#25232a, #25232a) padding-box,
            linear-gradient(to right, #4a52ff, #7b29ff) border-box;
          border-color: transparent;
          border-width: 1px;
          padding: calc(1.5rem + 1px);
        }
      }
      &:focus {
        box-shadow: none;
        background: linear-gradient(#25232a, #25232a) padding-box,
          linear-gradient(to right, #4a52ff, #7b29ff) border-box;
        border-width: 2px;
        border-color: transparent;
        padding: 1.5rem;
      }
    }

    textarea {
      resize: none;
    }

    .input-feedback {
      color: #ff4a60;
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: 500;
      text-align: end;
      margin-top: 0.5rem;
    }
  }

  .send-result-icon {
    background-color: #ff4a60;
    padding: 1.25rem;
    border-radius: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .send-result-message {
    font-size: 1.25rem;
    line-height: 150%;
    white-space: pre-line;
  }

  .recaptcha-info {
    font-size: 0.75rem;
    color: $font-color-gray;
    opacity: 0.5;
    cursor: default;
    bottom: 0.5rem;
    left: 1rem;
    right: 1rem;
    @include media-breakpoint-down(md) {
      font-size: 0.5rem;
    }
  }

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
