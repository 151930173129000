
@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: 300;
  src: url('../assets/fonts/SpaceGrotesk/SpaceGrotesk-Light.ttf');
}

@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: 400;
  src: url('../assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.ttf');
}

@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: 500;
  src: url('../assets/fonts/SpaceGrotesk/SpaceGrotesk-Medium.ttf');
}

@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: 600;
  src: url('../assets/fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.ttf');
}

@font-face {
  font-family: 'SpaceGrotesk';
  font-weight: 700;
  src: url('../assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold.ttf');
}
